<template>
  <div v-if="!categoryIndex">
    <ChooseCategory @categorySelection="getCategoryDetails" />
  </div>
  <div v-else>
    <NavBar
      class="w-full fixed mb-10"
      :category-name="selectedCategoryName"
      :all-orders-count="selectedCategoryOrdersCount"
      :in-preparation-orders-count="selectedCategoryOrders.length"
      @refreshClick="getCategoryOrders()"
      @openMenuClick="
        () => {
          displaySidebarMenu = true;
        }
      "
      @newOrderClick="
        () => {
          if (!code) {
            displayNewOrderModal = true;
            return;
          }

          startNewOrder({ code });
        }
      "
    />

    <div class="container h-full mx-auto pt-20">
      <div v-show="selectedCategoryOrders.length" class="orders-container">
        <OngoingOrders
          ref="ordersComponent"
          class="pt-10 inline float-right"
          :orders="selectedCategoryOrders"
          :current-order-index="selectedOrderIndex"
          @orderSelection="displayOrderDetails"
        />

        <OrderDetails
          v-if="displayDetails"
          ref="detailsComponent"
          class="inline float-left mt-10"
          :order="selectedOrder"
          :tolerance="tolerance"
          @newOrderStatus="handleNewItemStatus"
          @finishOrderClick="finishOrder"
        />
      </div>

      <EmptyOrders
        v-show="!selectedCategoryOrders.length"
        :has-orders="selectedCategoryOrdersCount"
        class="mx-auto"
        @newOrderClick="
          () => {
            displayNewOrderModal = true;
          }
        "
      />
    </div>

    <SideBarMenu
      v-show="displaySidebarMenu"
      class="fixed top-0"
      @categorySelection="getCategoryDetails"
      @logoutClick="removeAuthentication"
      @click="
        e => {
          e.stopPropagation();
        }
      "
      @closeMenuClick="
        () => {
          displaySidebarMenu = false;
        }
      "
    />

    <StartOrderModal
      v-if="displayNewOrderModal"
      class="w-full"
      :valid-code="isValidCode"
      :can-start="canPull"
      @closeModalClick="
        () => {
          displayNewOrderModal = false;
        }
      "
      @newOrderCode="startNewOrder"
    />

    <Alert
      v-if="displayAlertModal"
      class="fixed top-0"
      :order-time="finishedOrderTime"
      @closeAlertClick="
        () => {
          displayAlertModal = false;
        }
      "
    />
  </div>
</template>

<script>
import ChooseCategory from '@/components/ChooseCategory';
import NavBar from '@/components/CategoriesNavBar';
import OngoingOrders from '@/components/OngoingOrders';
import EmptyOrders from '@/components/EmptyOrders';
import OrderDetails from '@/components/OrderDetails';
import SideBarMenu from '@/components/SideBarMenu';
import StartOrderModal from '@/components/StartOrderModal';
import Alert from '@/components/Alert';

import firebaseMessaging from './firebase';
import axios from 'axios';
let instance = '';

export default {
  components: {
    ChooseCategory,
    EmptyOrders,
    OngoingOrders,
    OrderDetails,
    NavBar,
    SideBarMenu,
    StartOrderModal,
    Alert,
  },
  emits: ['setAuthentication', 'removeAuthentication'],
  data: () => {
    return {
      selectedCategoryOrders: [],
      selectedOrder: {},
      categoryIndex: 0,
      selectedCategoryName: '',
      selectedCategoryValue: '',
      selectedCategoryOrdersCount: 0,
      inPreparationOrdersCount: 0,
      selectedOrderIndex: 0,
      displaySidebarMenu: false,
      displayNewOrderModal: false,
      displayAlertModal: false,
      displayDetails: false,
      finishedOrderTime: '',
      canPull: true,
      isValidCode: true,
      tolerance: 0,
      categories: [
        {
          name: 'قسم اللحوم',
          value: 'MEAT',
        },
        {
          name: 'قسم الجبن',
          value: 'CHEESE',
        },
        {
          name: 'قسم الأسماك',
          value: 'FISH',
        },
        {
          name: 'قسم الدواجن',
          value: 'CHICKEN',
        },
      ],
      code: '',
    };
  },

  mounted: function () {
    document.addEventListener('click', () => {
      this.displaySidebarMenu = false;
    });

    this.configureAxiosInstance();
    this.getCategoryDetails();
  },

  methods: {
    configureAxiosInstance() {
      instance = axios.create({
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${
            document.cookie
              .split('; ')
              .filter(row => row.startsWith('access'))[0]
              .split('=')[1]
          }`,
        },
      });
    },

    checkStoredToken() {
      if (!sessionStorage.getItem('notification_token')) this.getNotificationToken();
    },

    getNotificationToken() {
      firebaseMessaging
        .getToken({
          vapidKey: process.env.VUE_APP_VAPID_KEY,
        })
        .then(token => {
          sessionStorage.setItem('notification_token', token);
          this.sendNotificationToken(token);
        });
    },

    sendNotificationToken(token) {
      let tokenFormData = new FormData();
      tokenFormData.append('token', token);
      instance({
        method: 'post',
        url: `/api/notifications/kitchen/${this.selectedCategoryValue}/subscribe`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: tokenFormData,
      }).then(() => {
        this.subscribeForEvents();
      });
    },

    subscribeForEvents() {
      firebaseMessaging.onMessage(msg => {
        this.getCategoryOrders();
      });

      firebaseMessaging.onBackgroundMessage(msg => {
        this.getCategoryOrders();
        self.registration.showNotification();
      });
    },

    displayOrderDetails(e) {
      this.displayDetails = false;
      setTimeout(() => {
        this.displayDetails = true;
        this.selectedOrderIndex = e.orderIndex;
        this.selectedOrder = e.order;
      }, 1);
    },

    handleNewItemStatus(e) {
      instance
        .post(`/api/items/${e.itemID}/kitchen/${e.itemStatus == 'IN_PROGRESS' ? 'verify' : 'reject'}`, {
          qty: e.itemQuantity,
        })
        .then(() => {
          this.selectedOrder.items[e.itemNumber].is_prepared = 1;
          this.selectedOrder.items[e.itemNumber].status = e.itemStatus;

          this.$refs.detailsComponent.updateQuantity(e.itemNumber);
          this.$refs.detailsComponent.$forceUpdate();
        });
    },

    finishOrder(data) {
      instance.post(`/api/orders/order/${data.id}/${this.selectedCategoryValue}/finish`).then(() => {
        this.finishedOrderTime = this.selectedOrder.time;
        this.displayAlertModal = true;
        this.selectedCategoryOrders.splice(this.selectedOrderIndex, 1);
        this.selectedOrder = {};
        this.displayDetails = false;

        setTimeout(() => {
          this.displayAlertModal = false;
        }, 5000);
      });
    },

    startNewOrder(data) {
      let formData = new FormData();
      formData.append('code', data.code);

      instance({
        method: 'post',
        url: `/api/orders/${this.selectedCategoryValue}/pull`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
        .then(response => {
          this.code = data.code;
          let order = response.data.order;
          this.selectedCategoryOrders.push(order);
          this.configureOrderTimeRelatedData();
          this.displayNewOrderModal = false;
          if (this.selectedCategoryOrdersCount) this.selectedCategoryOrdersCount -= 1;
          this.selectedOrderIndex = this.selectedCategoryOrders.length - 1;
          this.selectedOrder = this.selectedCategoryOrders[this.selectedOrderIndex];
          this.displayDetails = true;
          this.$refs.ordersComponent.selectThePulledOrder(this.selectedOrderIndex);
        })
        .catch(error => {
          if (this.code) {
            this.code = '';
            this.displayNewOrderModal = true;
          }

          if (error?.response?.status == 500) this.canPull = false;
          else if (error?.response?.status == 400) this.isValidCode = false;

          setTimeout(() => {
            // this.displayNewOrderModal = false;
            this.canPull = true;
            this.isValidCode = true;
          }, 5000);
        });
    },

    getCategoryDetails(e) {
      this.selectedOrder = '';
      this.displayDetails = false;
      this.displaySidebarMenu = false;

      this.configureCategoryInfo(e);
    },

    configureCategoryInfo(e) {
      if (e?.categoryIndex != undefined) {
        sessionStorage.setItem('categoryIndex', e.categoryIndex);
        this.categoryIndex = e.categoryIndex;
      } else {
        this.categoryIndex = sessionStorage.getItem('categoryIndex') || 0;
      }

      if (this.categoryIndex) {
        this.selectedCategoryValue = this.categories[this.categoryIndex - 1].value;
        this.selectedCategoryName = this.categories[this.categoryIndex - 1].name;
        this.getCategoryOrders();
        this.checkStoredToken();
      }
    },

    getCategoryOrders() {
      instance
        .get(`/api/orders/${this.selectedCategoryValue}/details`)
        .then(response => {
          this.selectedCategoryOrders = response.data.orders;
          this.selectedCategoryOrdersCount = response.data.count;
          this.configureOrderTimeRelatedData();
          this.getTolerance();
        })
        .catch(() => {
          this.refreshAccessToken();
        });
    },
    getTolerance() {
      instance.get(`/api/items/tolerance`).then(response => {
        this.tolerance = response.data.tolerance_rate / 100;
      });
    },

    refreshAccessToken() {
      return instance
        .post('/api/oauth/token', {
          grant_type: 'refresh_token',
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          refresh_token: localStorage.getItem('refreshToken'),
          scope: '',
        })
        .then(response => {
          this.$emit('setAuthentication', response.data);
          this.configureAxiosInstance();
          this.getCategoryOrders();
        })
        .catch(() => {
          this.removeAuthentication();
        });
    },

    configureOrderTimeRelatedData() {
      this.selectedCategoryOrders.forEach(order => {
        this.calculateTiming(order);

        setInterval(() => {
          this.calculateTiming(order);
        }, 1000);
      });
    },

    calculateTiming(o) {
      const startTime = new Date(o.kitchen_started_at).getTime();
      const now = new Date().getTime();

      o.slaTimeHours = new Date(now - startTime).getUTCHours();
      o.slaTimeMinutes = o.slaTimeHours * 60 + new Date(now - startTime).getUTCMinutes();
      o.slaTimeSeconds = new Date(now - startTime).getUTCSeconds();

      o.time = `${o.slaTimeMinutes.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}:${o.slaTimeSeconds.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}`;

      o.timeLapsedInMinutes = new Date(now - startTime).getMinutes();
      o.slaStatus = this.slaResolver(o.timeLapsedInMinutes, o.out_of_sla_in);
    },

    slaResolver(slaTime, outOfSlaLimit) {
      if (slaTime > outOfSlaLimit) {
        return 'out-of-sla';
      }

      if (slaTime / 2 > outOfSlaLimit) {
        return 'mid-of-sla';
      }

      return 'in-sla';
    },

    removeAuthentication() {
      this.$emit('removeAuthentication');
    },
  },
};
</script>

<style>
body {
  background-color: #e5e5e5;
}

button:active {
  outline: none;
}

.mark {
  margin: auto;
}

.orders-container {
  display: grid;
  grid-template-columns: 1fr 451px;
  column-gap: 80px;
}
</style>
